<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <img :src="data.profile_pic" style="border-radius: 50%; width: 80%;" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-3/4 mb-base">
        <div class="vx-row">
          <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-3/3 xl:w-3/3 mb-base">
            <vx-card class="overflow-hidden">
              <div slot="no-body">
                <div class="p-6 text-left">
                  <h2 class="mb-base">{{this.id}}</h2>
                  <h3>{{data.biography}}</h3>
                </div>
              </div>
            </vx-card>
          </div>
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <statistics-card-line
              icon="UsersIcon"
              statistic="Following"
              :statisticTitle="data.following_count.count"
              type="area"
            ></statistics-card-line>
            <statistics-card-line
              class="mt-base"
              icon="UsersIcon"
              statistic="Follower"
              :statisticTitle="data.follower_count.count"
              color="warning"
              type="area"
            ></statistics-card-line>
            <!-- </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/2 mb-base">-->
            <!-- <statistics-card-line
              icon="UsersIcon"
              statistic="Follower"
              :statisticTitle="data.follower_count.count"
              color="warning"
              type="area"
            ></statistics-card-line>-->
            <statistics-card-line
              class="mt-base"
              icon="UsersIcon"
              statistic="Posts"
              :statisticTitle="posts.length"
              color="success"
              type="area"
            ></statistics-card-line>
          </div>
          <!-- <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/2 mb-base">
            <statistics-card-line
              icon="UsersIcon"
              statistic="Posts"
              :statisticTitle="posts.length"
              color="success"
              type="area"
            ></statistics-card-line>
          </div>-->
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/2 mb-base">
            <!-- {{overal_sentiment}} -->
            <chartjs-bar-chartS
              :posetive="overal_sentiment.positive*100"
              :negetive="overal_sentiment.negative*100"
              :neutral="overal_sentiment.neutral*100"
            ></chartjs-bar-chartS>
          </div>
        </div>
      </div>
      <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-4/4 xl:w-4/4 mb-base mt-4">
        <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-4/4 xl:w-4/4 mb-4">
          <div class="text-left">
            <h2>Overal Comment Entropy Analysis:</h2>
          </div>
        </div>
        <div slot="no-body" class="custom-card vx-row">
          <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-2/2 xl:w-2/2 mb-base">
            <div class="text-center mb-base">
              <h3 class="text-primary">Sentiment</h3>
            </div>

            <vue-apex-charts
              type="radialBar"
              height="345"
              class="mt-10 mb-10"
              :options="chartOptionsEngagement"
              :series="[73]"
            />
          </div>
          <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-2/2 xl:w-2/2 mb-base">
            <div class="text-center mb-base">
              <h3 class="text-primary">Engagement</h3>
            </div>

            <vue-apex-charts
              type="radialBar"
              height="345"
              class="mt-10 mb-10"
              :options="chartOptionsSentiment"
              :series="[40]"
            />
          </div>
          <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-2/2 xl:w-2/2 mb-base">
            <div class="text-center mb-base">
              <h3 class="text-primary">Intimacy</h3>
            </div>

            <vue-apex-charts
              type="radialBar"
              height="345"
              class="mt-10 mb-10"
              :options="chartOptionsIntimacy"
              :series="[36]"
            />
          </div>
        </div>

        <div class="vx-row mt-base custom-card">
          <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-2/3 xl:w-2/3 text-left">
            <h2>Max Loyality</h2>
            <engagement class="mt-base" :customData="maxflowers" />
            <!-- <maxengagement class="mt-base" :customData="minflowers" v-if="minflowers.length <=10" /> -->
            <h2>Min Loyality</h2>
            <engagement class="mt-base" :customData="minflowers" />

            <!-- <minengagement class="mt-base" :customData="minflowers" v-if="minflowers.length <=10" /> -->
          </div>
          <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-1/3 xl:w-1/3">
            <div>
              <div class="text-left mt-base">
                <h2 class="mb-base">Loyalty</h2>
                <hr />
                <div class="mb-base">
                  <h4 class="mt-base">Abstract</h4>
                  <p>User loyalty can be measured using Instagram and analysis of Instagram related data. The sentiment analysis on comments gives an opinion of users in terms of sentiment scores. The instagram also provides various other information such as a number of likes, comments and etc.</p>
                  <!-- <br /> -->
                  <p>Here an idea proposed to compute the user loyalty using Instagram related information. The posts related to the Iyla and Izo are collected from instagram. These Influencers have various followers, comments and likers, The objective is to computer consumer loyalty to a specific influencer based their activity of followers history.</p>
                </div>
                <div>
                  <h4>Loyalty Measurement</h4>
                  <p>Using Ai algorithms the sentiment and profanity score for each comment is computed. The comments having sentiment score greater than zero are considered as positive and less than zero are taken as negative tweets. Also, The comments having profanity score greater than 0.5 are considered as positive and less than 0.5 are taken as negative tweets.</p>
                </div>
              </div>
            </div>
            <div class="mt-base">
              <chartjs-bar-chart
                title="Loyalty (%)"
                :Disloyal="loyal.disloyal"
                :Loyal="loyal.loyal"
                :Neutral="loyal.neutral"
              ></chartjs-bar-chart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <charts :posts="posts" />
  </div>
</template>

<script>
import engagement from "./engagement.vue";
import ChartjsBarChartS from "../ChartjsBarChart.vue";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import charts from "./charts.vue";
import ChartjsBarChart from "../ChartjsBarChartOverView.vue";
import VueApexCharts from "vue-apexcharts";
import analyticsData from "../analyticsData.js";
import ChartjsComponentPieChart from "../ChartjsComponentPieChart.vue";
export default {
  components: {
    engagement,
    StatisticsCardLine,
    charts,
    ChartjsBarChart,
    VueApexCharts,
    ChartjsBarChartS
  },
  // props: ["id"],
  data() {
    return {
      overal_sentiment: Number,
      maxflowers: [],
      minflowers: [],
      posts: undefined,
      id: this.$route.params.username,
      chartOptionsEngagement: {
        labels: ["High", "Sentiment", "Intimacy"],
        dataLabels: {
          enabled: false
        },
        legend: { show: false },
        chart: {
          type: "pie",
          offsetY: 0,
          dropShadow: {
            enabled: true,
            blur: 5,
            left: 1,
            top: 1,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 5
        },
        colors: ["#7961F9", "#FF9F43", "#EA5455"],
        fill: {
          type: "gradient",
          gradient: {
            gradientToColors: ["#9c8cfc", "#FFC085", "#f29292"]
          }
        }
      },
      chartOptionsSentiment: {
        labels: ["Medium", "Engagement", "Intimacy"],
        dataLabels: {
          enabled: false
        },
        legend: { show: false },
        chart: {
          type: "pie",
          offsetY: 0,
          dropShadow: {
            enabled: true,
            blur: 5,
            left: 1,
            top: 1,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 5
        },
        colors: ["#f29292", "#7961F9", "#EA5455"],
        fill: {
          type: "gradient",
          gradient: {
            gradientToColors: ["#7961F9", "#FFC085", "#f29292"]
          }
        }
      },
      chartOptionsIntimacy: {
        labels: ["low", "Engagement", "Sentiment"],
        dataLabels: {
          enabled: true
        },
        legend: { show: false },
        chart: {
          type: "pie",
          offsetY: 0,
          dropShadow: {
            enabled: true,
            blur: 5,
            left: 1,
            top: 1,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 5
        },
        colors: ["#FF9F43", "#7961F9", "#FF9F43"],
        fill: {
          type: "gradient",
          gradient: {
            gradientToColors: ["#FF9F43", "#FFC085", "#f29292"]
          }
        }
      },
      data: null,
      customersData: {},
      analyticsData: analyticsData,
      loyal: {}
    };
  },
  watch: {
    $route() {
      this.$router.go()((this.id = this.$route.params.username)),
        this.getData(this.id);
      this.getPosts(this.id);
      this.getFlowersData(this.id);
    }
  },
  mounted() {
    this.getData(this.id);
    this.getPosts(this.id);
    this.getFlowersData(this.id);
  },
  created() {
    this.$http
      .get("/api/card/card-analytics/customers")
      .then(response => {
        this.customersData = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    splitData(data) {
      this.minflowers = [];
      this.maxflowers = [];
      data.forEach(element => {
        // if (element.status === "min") {
        //   this.minengagement.push(element);
        // } else {
        //   this.maxengagement.push(element);
        // }
        if (element.status == "min" && this.minflowers.length != 10) {
          this.minflowers.push(element);
        } else if (element.status == "max" && this.maxflowers.length != 10) {
          this.maxflowers.push(element);
        }
      });
    },
    getFlowersData(name) {
      this.$http
        .get(`${process.env.VUE_APP_NOT_SECRET_CODE}/instagram/loyalty/${name}`)
        .then(res => {
          this.splitData(res.data.data);
        });
    },
    getPosts(name) {
      this.$http
        .get(`${process.env.VUE_APP_NOT_SECRET_CODE}/instagram/post/${name}`)
        .then(res => {
          this.posts = res.data.posts;
        });
    },
    getData(name) {
      this.$http
        .get(`${process.env.VUE_APP_NOT_SECRET_CODE}/instagram/user/${name}`)
        .then(res => {
          if (name == "iyla") {
            this.overal_sentiment = {
              negative: 0.17588325652841783,
              neutral: 0.35253456221198154,
              positive: 0.4715821812596006
            };
          } else {
            this.overal_sentiment = {
              negative: 0.19162640901771336,
              neutral: 0.48470209339774556,
              positive: 0.32367149758454106
            };
          }
          (this.loyal = {}), (this.loyal = res.data.loyal);
          this.data = res.data;
        });
    }
  },
  name: "overview"
};
</script>
<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#chart2
  > div
  > svg:nth-child(1)
  > g:nth-child(1)
  > g:nth-child(3)
  > g:nth-child(1)
  > path:nth-child(1) {
  stroke: red !important;
}
.custom-card > div {
  padding: 0 30px;
  /* border-right: 1px solid black;
  border-left: 1px solid black; */
  text-align: center;
}
.custom-card {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  padding: 20px;
  background: white;
  min-height: 30px;
}
</style>