<!-- =========================================================================================
    File Name: ChartBarChart.vue
    Description: Create bar chart
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card title="Bar Chart" class="mb-base" code-toggler>
    <div class="vx-col w-full sm:w-1/1">
      <p>{{title}}</p>
    </div>
    <div class="mt-5">
      <chartjs-component-bar-chart :height="250" :data="data" :options="options" v-if="show"></chartjs-component-bar-chart>
    </div>

    <!-- <template slot="codeContainer">
      &lt;script&gt;
      import { Bar } from 'vue-chartjs'
      export default {
      extends: Line,
      data() {
      return {
      data: {
      labels: [&quot;Africa&quot;, &quot;Asia&quot;, &quot;Europe&quot;, &quot;Latin America&quot;, &quot;North America&quot;],
      datasets: [{
      label: &quot;Population (millions)&quot;,
      backgroundColor: [&quot;#3e95cd&quot;, &quot;#8e5ea2&quot;, &quot;#3cba9f&quot;, &quot;#e8c3b9&quot;, &quot;#c45850&quot;],
      data: [2478, 5267, 734, 784, 433]
      }]
      },
      options: {
      legend: { display: false },
      title: {
      display: true,
      text: 'Predicted world population (millions) in 2050'
      }
      }
      }
      }
      mounted () {
      this.renderChart(this.data, this.options)
      }
      }
      &lt;/script&gt;
    </template>-->
  </vx-card>
</template>

<script>
import ChartjsComponentBarChart from "./ChartjsComponentBarChart.vue";
export default {
  props: {
    title: String,
    Loyal: Number,
    Disloyal: Number,
    Neutral: Number
  },
  data() {
    return {
      show: false,
      data: {
        labels: ["Positive", "Negative", "third"],
        datasets: [
          {
            backgroundColor: ["#28C76F", "#EA5455", "#737272"],
            data: [0, 100]
          }
        ]
      },
      options: {
        legend: { display: false },
        title: {
          display: false,
          text: "Predicted world population (millions) in 2050"
        }
      }
    };
  },
  watch: {
    $route() {
      this.data.datasets[0].data = [0, 100];
      this.show = false;
      setTimeout(() => {
        this.setData();
      }, 1);
    }
  },
  mounted() {
    this.setData();
  },
  methods: {
    setData() {
      this.data.labels = ["Loyal", "Casual", "Neutral"];

      this.data.datasets[0].data.unshift(Math.ceil(this.Neutral * 100));

      this.data.datasets[0].data.unshift(
        Math.abs(Math.ceil(this.Disloyal * 100))
      );
      this.data.datasets[0].data.unshift(Math.abs(Math.ceil(this.Loyal * 100)));

      this.show = true;
    }
  },
  components: {
    ChartjsComponentBarChart
  }
};
</script>
<style >
.vx-card .vx-card__header {
  display: none;
}
</style>