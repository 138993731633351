<template>
  <div id="app">
    <line-chart v-bind="lineChartProps" id="chart2"></line-chart>
    <line-chart v-bind="lineChartProps2" legend="true"></line-chart>
  </div>
</template>

<script>
import LineChart from "../../../layouts/components/chart/LineChart";

export default {
  name: "App",
  props: ["posts"],
  components: {
    LineChart
  },
  watch: {
    $route() {
      this.id = this.$route.params.username;
      this.lineChartProps2();
      this.lineChartProps();
    }
  },

  computed: {
    dayData2() {
      let positive = { name: "positive", values: [] };
      let negative = { name: "negative", values: [] };

      for (let i in this.posts) {
        negative.values.push({
          date: new Date(
            this.posts[i].post_timestamp * 1000
          ).toLocaleDateString("en-GB", { timeZone: "UTC" }),
          value: String(Math.round(this.posts[i].negative_comment_sentiments))
        });
        positive.values.push({
          date: new Date(
            this.posts[i].post_timestamp * 1000
          ).toLocaleDateString("en-GB", { timeZone: "UTC" }),
          value: String(Math.round(this.posts[i].positive_comment_sentiments))
        });
      }

      // let positive = { name: "positive", values: [] };
      return [positive, negative];
    },

    dayData() {
      let profanity = { name: "Profanity (%)", values: [] };
      // let sendements = { name: "Sendements", values: [] };

      for (let i in this.posts) {
        profanity.values.push({
          date: new Date(
            this.posts[i].post_timestamp * 1000
          ).toLocaleDateString("en-GB", { timeZone: "UTC" }),
          value: String(Math.round(this.posts[i].comment_profanities * 100))
        });
        // sendements.values.push({
        //   date: new Date(
        //     data.izoofficialmusic.post_timestamp[i] * 1000
        //   ).toLocaleDateString("en-GB", { timeZone: "UTC" }),
        //   value: String(data.izoofficialmusic.comment_sentiments[i])
        // });
      }

      return [profanity];
      //       {

      //         date: "14/09/2018",
      //         value: "18"
      //       },
      //       {
      //         date: "15/09/2018",
      //         value: "150"
      //       },
      //       {
      //         date: "16/09/2018",
      //         value: "4"
      //       },
      //       {
      //         date: "18/09/2018",
      //         value: "130"
      //       },
      //       {
      //         date: "19/09/2018",
      //         value: "135"
      //       }
      //     ]
      //   },
      //   {
      //     name: "sendements",
      //     values: [
      //       {
      //         date: "14/09/2018",
      //         value: "13"
      //       },
      //       {
      //         date: "15/09/2018",
      //         value: "130"
      //       },
      //       {
      //         date: "16/09/2018",
      //         value: "6"
      //       },
      //       {
      //         date: "18/09/2018",
      //         value: "140"
      //       },
      //       {
      //         date: "19/09/2018",
      //         value: "130"
      //       }
      //     ]
      //   }
      // ];
    },
    lineChartProps2() {
      return {
        xAxisLabel: "Timeline",
        yAxisLabel: " sentiments (%)",

        xKey: "date",
        yKey: "value",
        interval: "day",
        data: this.dayData2
      };
    },
    lineChartProps() {
      return {
        xAxisLabel: "Timeline",
        yAxisLabel: "profanity (%)",
        xKey: "date",
        yKey: "value",
        interval: "day",
        data: this.dayData
      };
    }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 60px;
}

#chart2
  > div
  > svg:nth-child(1)
  > g:nth-child(1)
  > g:nth-child(3)
  > g:nth-child(1)
  > path:nth-child(1) {
  stroke: red !important;
}
</style>
